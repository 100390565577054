@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Urbanist&display=swap');


:root {
  --toastify-toast-width: 320px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 82px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;

}

body {
  margin: 0;
  font-family: Lato , sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
 
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
 
 
 
/* -------------------------custom css----------------------- */
.greenbg {
  background-color: #1EB469;
}
 
.greenborder {
  border:1px solid #1EB469;
}
.greenbg:hover {
  background-color: #008243
}
 
.primary-text-red{
  color:#FF3A29
}
 
.primary-text-gray{
  color:#80828D
}

.secondary-text-gray{
  color :#2C2C2C
}

.tertiary-text-gray {
  color: #62636C
}
 
.light-gray-background{
  background-color:#F9F9FB
}

.bg-grey {
  background-color: #EFF0F3;
}
 
.primary-text-green{
  color: #1EB469;
}
 
.redfolicbg {
 
  color: #1EB469;
  border: 1px solid #1EB469;
  }
 
.redfolicbg:hover {
  background-color:#FF0059;
  color : white;
  border: none;
}

.redfolicbg1 {
 
  color: black;
  border: 1px solid black;
  }
 
.redfolicbg1:hover {
  background-color:#FF0059;
  color : white;
  border: 1px solid #FF0059;
}
 
 
.text-green{
  color: #1EB469;
}

.text-disabled {
  color: #8ED9B4 !important;
}
 
/* Default styles */
.commen-text {
  font-family: 'Urbanist', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #2C2C2C;
}

.mediumFont {
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
}

.h1-text {
  font-family: 'Urbanist', sans-serif;
  font-weight: bold;
  font-size: 64px;
}

.h2-text {
  font-family: 'Urbanist', sans-serif;
  font-size: 42px;
  font-weight: 700;
  line-height: 56px;
  text-align: center;
}

.h3-text {
  font-family: 'Urbanist', sans-serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 38.4px;
  color: #2C2C2C;
}

.h4-text {
  font-family: 'Urbanist', sans-serif;
  font-size: 28px;
  font-weight: 700;
  line-height: 38px;
}

.h4-text-mobile {
  font-family: 'Urbanist', sans-serif;
  font-size: 28px;
  font-weight: 700;
  line-height: 38px;
}

.h5-text {
  font-family: 'Lato', sans-serif;
  font-size: 22px;
  font-weight: 700;
  line-height: 32px;
}

.h6-text {
  font-family: 'Lato', sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 21.6px;
  text-align: left;
}

.s1-text {
  font-family: 'Lato', sans-serif;
  font-size: 22px;
  font-weight: 500;
  line-height: 32px;
  text-align: left;
}

.s2-text {
  font-family: 'Lato', sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 21.6px;
  text-align: left;
  color: #2C2C2C;
}

.s3-text {
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
}

.body-text {
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  font-weight: 400 !important;
  line-height: 18px;
}

.simple-text {
  font-family: 'Lato', sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
}

.remove-text {
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 16.8px;
  text-align: center;
  color: #1EB469;
}

.hr-bgColor {
  background-color: #E7E8EC;
  border: 1px solid #E7E8EC;
}

/* Responsive styles */
@media (max-width: 640px) { /* Small screens (phones) */
  .commen-text {
    font-size: 14px;
    line-height: 20px;
  }

  .mediumFont {
    font-size: 14px;
    line-height: 18px;
  }

  .h1-text {
    font-size: 48px;
  }

  .h2-text {
    font-size: 36px;
  }

  .h3-text {
    font-size: 28px;
  }

  .h4-text, .h4-text-mobile {
    font-size: 24px;
  }

  .h5-text {
    font-size: 20px;
  }

  .h6-text {
    font-size: 16px;
  }

  .s1-text {
    font-size: 20px;
  }

  .s2-text {
    font-size: 16px;
  }

  .s3-text {
    font-size: 14px;
  }

  .body-text {
    font-size: 14px;
  }

  .simple-text {
    font-size: 16px;
  }

  .remove-text {
    font-size: 12px;
  }
}

@media (min-width: 641px) and (max-width: 768px) { /* Medium screens (tablets) */
  .commen-text {
    font-size: 15px;
  }

  .mediumFont {
    font-size: 15px;
  }

  .h1-text {
    font-size: 56px;
  }

  .h2-text {
    font-size: 40px;
  }

  .h3-text {
    font-size: 30px;
  }

  .h4-text, .h4-text-mobile {
    font-size: 26px;
  }

  .h5-text {
    font-size: 22px;
  }

  .h6-text {
    font-size: 18px;
  }

  .s1-text {
    font-size: 22px;
  }

  .s2-text {
    font-size: 18px;
  }

  .s3-text {
    font-size: 16px;
  }

  .body-text {
    font-size: 14px;
  }

  .simple-text {
    font-size: 18px;
  }

  .remove-text {
    font-size: 14px;
  }
}


.Toastify__toast-container--top-right {
  top: 5%;
}

.Toastify__toast-body {
  color: black;
  padding-bottom: 7px !important;
  padding-block: 0.5rem !important;
}

.Toastify__toast-icon {
  margin-top: 0.08rem;
  width: 28px !important;
  height: 28px;
}

input[type="checkbox"] {
  accent-color: #1EB469;
  
 

}
input[type="radio"] {
  accent-color: #1EB469;
}

.checkBox-label{

font-family: Lato ,sans-serif;
font-size: 14px;
margin-left: 10px;
font-weight: 400;
line-height: 18px;

color: #80828D;


}
 



.roundedBox{
  width: Hug (173px)px;
  height: Hug (40px)px;
  gap: 10px;
  text-align: left;
  border-radius: 21px;
  border: 1px solid #2C2C2C;

  
}

.cursor-original {
  cursor: default !important;
}


/* -------------------------custom loader css----------------------- */
.loader{
  margin: 0 0 2em;
  height: 100px;
  width: 20%;
  text-align: center;
  padding: 1em;
  margin: 0 auto 1em;
  display: inline-block;
  vertical-align: top;
}

.enbl-bg {
  enable-background: new 0 0 50;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 2px;
  height:2px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 4px;
}

.notification{
  font-family: Lato ,sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  

}
.notification-span{
  font-family: Lato ,sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;

  
  }

  .notification-ptag{
    font-family: Lato ,sans-serif ;
font-size: 12px;
font-weight: 400;
line-height: 14.4px;
text-align: left;
color :#8B8D98

  }


  .phone-dropdown {
    text-align: left;
  }
  
  .phone-dropdown .country-list .country {
    text-align: left;
  }

  input[type="password"]::-ms-reveal {
    display: none;
  }


  .salary-details {
    padding: 6px 15px 6px 15px;
    border-radius: 24.5px;
  }

  .calendar-container {
  background-color: white;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.fc .fc-toolbar-title {
  font-size: 1.25rem;
  color: #333333;
}

.fc .fc-button {
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 12px;
}

.fc .fc-button:hover {
  background-color: #45a049;
}

.fc .fc-daygrid-event, .fc .fc-timegrid-event {
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 2px 4px;
}

.fc .fc-daygrid-event:hover, .fc .fc-timegrid-event:hover {
  background-color: #45a049;
}


.react-calendar{
  border: 0px !important;
 
}

.react-calendar__tile--active{

  border-radius: 100% !important;
  background-color: #1EB469 !important;
  border: 0px !important;
  
}

.react-calendar__tile{
  padding: 10px !important;
}

.react-calendar__tile--now{
  background-color: #1EB469 !important;
  border-radius: 100% !important;
}




.fc .fc-daygrid-day-frame {
  border: none !important;
}

.fc .fc-col-header-cell {
  border: none !important;
}

.fc .fc-daygrid-body {
  border-top: none !important;
}



.fc .fc-daygrid-day-frame {
  border: none !important;
  padding: 4px !important; /* Adjust the padding as needed */
}

.fc .fc-col-header-cell {
  border: none !important;
  padding: 4px !important; /* Adjust the padding as needed */
}

.fc .fc-daygrid-body {
  border-top: none !important;
}




.fc .fc-daygrid-day {
  height: 80px !important; /* Adjust the height as needed */
}

.fc .fc-daygrid-table {
  border-collapse: collapse !important;
}

.fc .fc-daygrid-day-number {
  margin-top: 4px !important; /* Adjust the top margin as needed */
}

.fc .fc-daygrid-day-frame {
  border: none !important;
  padding: 2px !important; /* Adjust the padding for balanced spacing */
}

.fc .fc-col-header-cell {
  border: none !important;
  padding: 2px !important; /* Adjust the padding for balanced spacing */
}

.fc .fc-daygrid-body {
  border-top: none !important;
}

.fc .fc-daygrid-day-top {
  /* height: 10vh !important; */
  border: none !important;
  /* background-color: red;  */
  display: flex;
  justify-content: center;
}

.fc .fc-daygrid-day {
  height: 60px !important; /* Adjust the height as needed */
}

.fc .fc-daygrid-table {
  border-collapse: collapse !important;
}

.fc .fc-daygrid-day-number {
  padding: 0px !important;
  margin-top: 0px !important; /* Adjust the top margin as needed */
}




/* Remove internal borders */
.fc .fc-daygrid-day-frame {
  border: none !important;
  padding: 2px !important;
}

.fc .fc-col-header-cell {
  border: none !important;
  padding: 2px !important;
}

.fc .fc-daygrid-body {
  border-top: none !important;
}



.fc .fc-daygrid-day {
  height: 60px !important;
}

.fc .fc-daygrid-table {
  border-collapse: collapse !important;
}

.fc .fc-daygrid-day-number {
  margin-top: 2px !important;
}

/* Custom header cells to show only the first letter of the day */
.fc-col-header-cell-cushion {
  font-size: 1rem !important;
}

.fc .fc-col-header-cell-cushion {
  text-align: center;
  padding: 4px 0 !important;
}

.fc .fc-daygrid-day-frame {
  padding: 4px 0 !important;
}

.fc .fc-daygrid-body-natural .fc-daygrid-day-events {
  margin-bottom: 0px !important;
}
.fc .fc-daygrid-day-events{
  margin-top: 0px !important; 
}

.fc .fc-daygrid-day {
  height: 29px !important;
}

.fc .fc-daygrid-body-natural .fc-daygrid-day-events {
  margin-bottom: 0px !important;
  display: none !important;
}

.fc .fc-daygrid-day-frame {
  padding: 1px 0 !important;
}

.fc .fc-daygrid-day-number {
  margin-top: 0px !important;
}

.fc .fc-daygri.fc .fc-daygrid-day-topd-day-number {
  padding: 0px !important;
}

.fc-theme-standard .fc-scrollgrid {
  border: none !important 
}


.fc-theme-standard td, .fc-theme-standard th {
  border: none !important;
}

.fc-daygrid-body.no-border {
  border-top: none !important;
}

.no-border {
  border-top: none !important;
}



.sticky-timeline {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 58px; /* Adjust this value based on your layout */
  z-index: 50; /* Make sure it stays on top if needed */
  background-color: white; /* Optional: Set a background color to avoid content being overlapped */
}
.sticky-AddJob {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 60px; /* Adjust this value based on your layout */
  z-index: 50; /* Make sure it stays on top if needed */
  background-color: white; /* Optional: Set a background color to avoid content being overlapped */
}
.sticky-dashboard1 {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 58px; /* Adjust this value based on your layout */
  z-index: 20; /* Make sure it stays on top if needed */
  background-color: white; /* Optional: Set a background color to avoid content being overlapped */
}

.sticky-dashboard {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 120px; /* Adjust this value based on your layout */
  z-index: 20; /* Make sure it stays on top if needed */
  background-color: white; /* Optional: Set a background color to avoid content being overlapped */
}

.sticky-dashboard2 {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 150px; /* Adjust this value based on your layout */
  z-index: 20; /* Make sure it stays on top if needed */
  background-color: white; /* Optional: Set a background color to avoid content being overlapped */
}

.sticky-welcomeBK {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 58px; /* Adjust this value based on your layout */
  z-index: 20; /* Make sure it stays on top if needed */
  background-color: white; /* Optional: Set a background color to avoid content being overlapped */
}

.sticky-tabs {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 140px; /* Adjust this value based on your layout */
  z-index: 20; /* Make sure it stays on top if needed */
  background-color: white; /* Optional: Set a background color to avoid content being overlapped */
}

.sticky-tabs1 {
  position: -webkit-sticky; /* For Safari */
  position: sticky;

  top: 180px; /* Adjust this value based on your layout */
  z-index: 20; /* Make sure it stays on top if needed */
 /* Optional: Set a background color to avoid content being overlapped */
}

.custom-input::placeholder {
  font-size: 14px; /* Adjust font size */
  color: #888; /* Adjust color */
  text-overflow: ellipsis; /* Truncate long text with ellipsis */
  white-space: nowrap; /* Prevent text wrapping */
  overflow: hidden; /* Hide overflow */
}

.sticky-BankDetail{
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 140px; /* Adjust this value based on your layout */
  z-index: 20; /* Make sure it stays on top if needed */
   /* Optional: Set a background color to avoid content being overlapped */
}

.sticky-profile{
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  background-color: rgb(255, 253, 253);
  top: 58px; /* Adjust this value based on your layout */
  z-index: 40; /* Make sure it stays on top if needed */
   /* Optional: Set a background color to avoid content being overlapped */
}

.tooltip-content {
  background-color: none;
  
}
.imgClassHeight{
  height: -webkit-fill-available !important;
}


.checked\:before\:content-\[\'\'\]:checked::before {
  content: '';
  display: block;
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='10' viewBox='0 0 14 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.5 5L5.5 9L13 1.5' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  background-size: contain;
  background-repeat: no-repeat;
}

.custom-timepicker {
  @apply border-2 border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500;
}

.custom-clock {
  @apply bg-white shadow-lg rounded-lg;
}