.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
  gap: 8px;
}

.pagination-button {
  width: 30px;
  height: 30px;
  border: 1px solid #ddd;
  border-radius: 50%;
  background-color: white;
  color: #555;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  outline: none;
}

.pagination-button.active {
  background-color: #1EB469
  ;
  color: white;
}

.pagination-button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
